import React from "react";
import car_1 from "../img/WebDevelopment&Design-car-2.webp";
import { useState } from "react";
import car_2 from "../img/Digital-Marketing-car-1c.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from "aos";

// AOS.init();
AOS.init({
  duration: 1000,
});

function Slider_slick() {
  const data = {
    sliderdata: [
      {
        img1: car_1,
        txt: "Website Development & Designing",
        txt2: "We think differently than traditional designers, marketers, and thinkers. As a result, we begin with the end consumers. To better assist our users, we strive to empathise with them and understand their perspectives.",
      },
      {
        img1: car_2,
        txt: "Digital marketing",
        txt2: "An ideal digital marketing strategy includes SEO, SEM, PPC, SMO, content marketing, and social media networking, among other things.",
      },
    ],
  };

  const PrevButton = ({ onClick }) => (
    <button className="slick-prevv" onClick={onClick}>
      <i className="bi bi-chevron-left"></i>
    </button>
  );

  const NextButton = ({ onClick }) => (
    <div className="slick-nextt" onClick={onClick}>
      <i className="bi bi-chevron-right"></i>
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="slider-container">
        <Slider {...settings}>
          {data.sliderdata.map((e, i) => {
            return (
              <div key={i}>
                <div
                  className="bg_img"
                  style={{ backgroundImage: `url(${e.img1})` }}
                >
                  <div className="layer_slider">
                    <div className="h-100 d-flex align-items-center">
                      <div className="row slider_ms">
                        <div className="col-lg-8 col-md-10">
                          <h1
                            className="text-primary mb-3 fs-sm-25px fs-20px d-none"
                            data-aos="fade-down"
                          >
                            Best Online Courses
                          </h1>
                          <h1
                            className="text-white fw-bolder fs-xxl-80px  fs-lg-64px fs-md-50px  fs-40px"
                            data-aos="fade-down"
                            data-aos-delay="150"
                          >
                            {e.txt}
                          </h1>
                          <h6 className="text-white fs-lg-24px fs-md-20px fs-18px">
                            {e.txt2}
                          </h6>
                          <div className="d-flex gap-3 flex-wrap mt-4 d-none">
                            <button
                              className="btn btn-primary btn_slider fs-5 rounded-0"
                              data-aos="fade-right"
                            >
                              Read More
                            </button>
                            <button
                              className="btn btn-light btn_slider fs-5 rounded-0"
                              data-aos="fade-left"
                            >
                              Join Now
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </>
  );
}

export default Slider_slick;
