
import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useState } from 'react'
import { Link ,NavLink } from 'react-router-dom'
import img from '../img/CouponLocus_WebsiteLogo.png'


function Navb_ars() {
    let [links,setLinks] = useState([{text:"Home",link:"/",type:"link"},{text:"About",link:"/about",type:"link"},{text:"Services",type:"dropdown",
      data:[
        {name:"Website Development & Designing",link:"/websiteDevelopmentAndDesigning"},
        {name:"Mobile Application Development",link:"/mobileApplicationDevelopment"},
        {name:"Digital marketing",link:"/digitalMarketing"},
        {name:"Branding",link:"/branding"},
        {name:"Interface Design",link:"/interfaceDesign"},
        {name:"Google Ads",link:"/googleAds"},
        {name:"SEO Services",link:"/seoServices"},
        {name:"Hosting & Gateway Services",link:"/hostingAndGatewayServices"},
      ]},
      {text:"Contact",link:"/contact",type:"link"},])  

      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }


  return (
    <>
    <Navbar bg="white" className='p-lg-0 py-0 px-sm-3 px-2 fixed-top py-2 top-0 ' expand="lg">
      <Container fluid className='px-sm-0 px-0'>
        <Navbar.Brand className='ps-lg-5 ps-2 d-flex align-items-center'>
        <NavLink  to="/" ><img src={img} />
        </NavLink></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto gap-lg-3 align-items-center  gap-0 me-sm-5">
          {links.map((e,i) => {
            if(e.type === "link"){
                return (
                <div key={i++} className='fw-bolder  nav_text_link text-center fs-17px' onClick={scrollToTop}>
                    <NavLink key={i++} to={e.link} className={({ isActive, isPending }) =>isPending ? "pending " : isActive ? "active" : ""}>
                        {e.text}
                        </NavLink>
                 </div>
                
                )
            }
             else if(e.type === "dropdown"){
                return (
                  <NavDropdown key={i++} title={e.text} className='fw-bold text-center  nav_text_link fs-17px' id="basic-nav-dropdown">
                    {e.data.map(linkdata => {
                      return (<NavDropdown.Item key={i++} as={Link} to={linkdata.link} onClick={scrollToTop}><Link key={i++} onClick={scrollToTop} to={linkdata.link}>{linkdata.name}</Link></NavDropdown.Item>)
                    })}
                  </NavDropdown>
                )
            }
            
          })}
          </Nav>
           
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  )
}

export default Navb_ars