import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import testtestimonial_1 from "../component/img/testimonial-1.jpg";
import testtestimonial_2 from "../component/img/testimonial-2.jpg";
import testtestimonial_3 from "../component/img/testimonial-3.jpg";
import testtestimonial_4 from "../component/img/testimonial-4.jpg";

function Slider_Slick2() {

  const [data, setdata] = useState([
    testtestimonial_1,
testtestimonial_2,
testtestimonial_3,
testtestimonial_4
  ]);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    centerPadding: 10 + 'px',
    slidesToScroll: 3,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
<div className="py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="text-center">
                <h6 className="section-title bg-white text-center text-primary px-3">Testimonial</h6>
                <h1 className="mb-5">Our Students Say!</h1>
            </div>
            <div className="testimonial-carousel position-relative">
            <Slider {...settings}>
          {data.map((e,i) => {
            return (
                <div className="testimonial-item text-center">
                    <img className="border rounded-circle p-2 mx-auto mb-3" src={e} style={{width: "80px" , height: "80px"}}/>
                    <h5 className="mb-0">Client Name</h5>
                    <p>Profession</p>
                    <div className="testimonial-text bg-light text-center p-4">
                    <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                    </div>
                </div>
                );
              })}
            </Slider>
            </div>
        </div>
    </div>
    </>
  );
}

export default Slider_Slick2;

