import React from 'react'
import { Header } from "../Header/Header"
import images from "../img/Website-Development-&-Designing_.webp"
import { Services_title } from './Services_title'
import { Services_content } from './services_content'
import { Services_content_right } from './services_content_right'

import img from '../img/Website Development & Designing/Content-Management-System.webp'
import img2 from '../img/Website Development & Designing/Custom-Application-Development.webp'
import img3 from '../img/Website Development & Designing/E-commerce-Development.webp'
import img4 from '../img/Website Development & Designing/Mean-Stack-Development.webp'
import img5 from '../img/Website Development & Designing/Social-Media-Apps.webp'
import { useEffect } from 'react'

function WebsiteDevelopmentAndDesigning() {
    useEffect(() => {
        document.title = "Website Development & Designing || Coupon Locus"
     }, []);
    let Services_title_list = {
        h1:"Website Development & Designing (coupon locus)",
        p1:"All under one roof, cross-browser and cross-device compatibility driven by mobile responsiveness. Our team will provide personalised web development solutions tailored to your needs, from simple website designs with CMS and online store creation to complicated company website apps and design solutions.",
        p2:"All under one roof, cross-browser and cross-device compatibility driven by mobile responsivenessWe will customise the best web development solutions for you, ranging from simple website designs with CMS and online store creation to extremely complicated company website apps and design solutions.",
        h2:"Our Web Development Services."
    }

    
    let services_content_list_1 = {
        title: "Custom Application Development",
        contact: " Iterative Development and Testing We adopt an iterative development approach, breaking the development process into manageable milestones. This allows us to continuously gather feedback from the client and make necessary adjustments along the way. Our quality assurance team conducts rigorous testing to identify and resolve issues at each stage, ensuring a stable and reliable application.",
        li: [],
        img: img2
    }
    let services_content_list_2 = {
        title: "Content Management System",
        contact: "We specialise in designing and developing web content management systems. We analyse and investigate market trends and combine them. Share your business requirements with us, and we'll create the perfect CMS framework for your company.",
        li: [],
        img: img
    }
    let services_content_list_3 = {
        title: "E-commerce Development",
        contact: "Our eCommerce platform offers an extensive catalogue of products, ranging from electronics, fashion, home goods, and beauty products to sports equipment and more. We collaborate with trusted vendors and brands to ensure a diverse selection that caters to different customer preferences and needs.",
        li: [],
        img: img3
    }
    let services_content_list_4 = {
        title: "Mean Stack Development",
        contact: "MEAN stack development website! Our team consists of skilled developers who specialise in their field of expertise. MEAN stack technology combines four powerful and popular technologies: MongoDB, Express.js, Angular, and Node.js. Here's a description of our MEAN stack development services.",
        li: [],
        img: img4
    }
    let services_content_list_5 = {
        title: "Social Media Apps",
        contact: "Social Media Apps website! We specialise in developing innovative and engaging social media applications that connect people, foster communities, and enable seamless communication and sharing. Here's a description of our social media app development services.",
        li: [],
        img: img5
    }

  return (
    <>
    <Header pagename="Website Development & Designing" image={images} path={[{link:"/",text:"Home"},{link:"/websiteDevelopmentAndDesigning",text:"Website Development & Designing"}]}/>
    <div>
    <Services_title {...Services_title_list}></Services_title>
        <div className="container pt-5 pb-3 remove_class_section_title">
            <div className="d-flex justify-content-center">
            <h5 className="section-title bg-white text-center text-dark px-3">Discovery and Requirement Gathering</h5>
            </div>
            <p> We begin by engaging in in-depth discussions with our clients to understand their business, target audience, goals, and specific requirements for the website. We gather information about their brand identity, desired features, functionality, and design preferences. This phase helps us align our development strategy with the client's vision.</p>
        </div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_1} ></Services_content>
            </div>
        </div>
       
        <div className="my-4 py-2">
            <div className="container">
                <Services_content_right {...services_content_list_2} ></Services_content_right>
            </div>
        </div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_3} ></Services_content>
            </div>
        </div>
       
        <div className="my-4 py-2">
            <div className="container">
                <Services_content_right {...services_content_list_4} ></Services_content_right>
            </div>
        </div>
        <div className="bg-light my-4 py-5">
            <div className="container">
                <Services_content {...services_content_list_5} ></Services_content>
            </div>
        </div>
    </div>
    </>
  )
}

export {WebsiteDevelopmentAndDesigning}