
import { Header } from "../Header/Header"
import images from "../img/Website-Development-&-Designing_.webp"
import {Service} from "../service/Service" 
import {AdvanceCard} from "../advancecard/advanceCard"
import { PeopleCard } from "../peopleCard/PeopleCard"
import { data } from "../peopleCard/peopledata"
import {Aboutdata} from "./data"

import {Courses} from "../Courses/Courses"
import { useEffect } from "react"
function About(){
    let aboutus = Aboutdata.headerCard
    useEffect(() => {
      document.title = "About Us || Coupon Locus"
   }, []);
   return (
    <>
      <Header pagename="About Us" image={images} path={[{link:"/",text:"Home"},{link:"/about",text:"About"}]}/>
      {/* <Service data={Aboutdata.servicedata}/> */}
      <AdvanceCard data={aboutus}/>
      {/* <PeopleCard data={data} name={"Expert Instructors"} context={"Expert Instructors"}/> */}
    </>
   )
}

export {About}