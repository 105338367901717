import React from 'react'
import { Header } from "../Header/Header"
import images from "../img/Interface-Design_.webp"
import { Services_title } from './Services_title'
import { Services_content } from './services_content'
import { Services_content_right } from './services_content_right'

import img from "../img/Interface Design/Blog-Layout.gif"
import img1 from "../img/Interface Design/Designing-for-Wearables.gif"
import img2 from "../img/Interface Design/Designing-for-wearables--2.gif"
import img3 from "../img/Interface Design/Iconography.gif"
import img4 from "../img/Interface Design/Illustrations.gif"
import img5 from "../img/Interface Design/Landing-Page-Design.gif"
import img6 from "../img/Interface Design/Mobile-UI-Design-2.gif"
import img7 from "../img/Interface Design/Mobile-UI-Design.gif"
import img8 from "../img/Interface Design/User-Interface-Design.gif"
import img9 from "../img/Interface Design/Website-Template-Design.gif"
import { useEffect } from 'react'

function InterfaceDesign() {
    useEffect(() => {
        document.title = "Interface Design || Coupon Locus"
     }, []);
    let Services_title_list = {
        h1:"Interface Design",
        p1:"Interfaces that are creative, appealing, engaging, and usable are the heart of any design. We will assist you with designing unique user interfaces for any device platform, including mobile apps, tablets, PCs, and KIOSKS, based on your business requirements.",
        p2:"Experts in interface design will ensure that a perfect interface has been developed to bridge the flow of the programme and the user experience when they are on your app. We understand what constitutes a fantastic user experience, which we will implement into our interface designs to ensure your clients are delighted when using your app.",
        h2:"We offer the below brand identity solutions."
    }

    
    let services_content_list_1 = {
        title: "User Interface Design",
        contact: "Acodez IT Solutions is a professional user interface design firm that provides UI design services to our client organisations, assisting them in developing a solid interface venture for their business and bringing it to the next level.",
        li: [],
        img: img8
    }
    let services_content_list_2 = {
        title: "Website Template Design",
        contact: "We are one of India's best web design and development firms, delivering the newest trends and technology in web design while experimenting with innovative concepts that will draw your clients to your website's visual appeal and professional aesthetics.",
        li: [],
        img: img9
    }
    let services_content_list_3 = {
        title: "Mobile UI Design",
        contact: "Acodez IT Solutions has extensive experience designing mobile apps that are fluid and appealing, providing the finest user experience possible with no defects. We can create mobile apps for any device. We also have experience customising cross-device mobile apps based on your company's needs.",
        li: [],
        img: img7
    }
    let services_content_list_5 = {
        title: "Illustrations",
        contact: "Simple is beautiful, and this is what we enjoy doing. We have a bright and dynamic staff of intellectually minded illustrators who are experienced and creative at creating out-of-the-box pictures that effectively express your ideas to your audience.",
        li: [],
        img: img4
    }
    let services_content_list_6 = {
        title: "Iconography",
        contact: "Creating a beautiful emblem that represents your brand is a complex undertaking. However, we are proud of our team of icon design professionals who can build exactly what your brand stands for, guaranteeing that it is imprinted in the minds of your customers.",
        li: [],
        img: img3
    }
    let services_content_list_7 = {
        title: "Landing Page Design",
        contact: "The landing page is the most critical aspect of every online sale. When it comes to your consumer making a sales decision, a flawlessly designed CTA or the colour of the submit button, for example, could be detrimental, and we know what exactly clicks with your customer. This is how we will create your landing pages.",
        li: [],
        img: img5
    }
    let services_content_list_8 = {
        title: "Blog Layout",
        contact: "A blog is one place where you can engage with your audience. You're making progress when you have achieved the correct path. Get your audience to read your posts. However, this can only happen if someone chooses to read your blog, which focuses on visuals. The effect and we are masters at building visually appealing blogs that will attract your people.",
        li: [],
        img: img
    }
    let services_content_list_9 = {
        title: "Designing for Wearables",
        contact: "Wearable design is challenging. When designing for wearables, a lot of factors must be considered. Acodez combines functionality, size, and visual impact to develop unusual wearable apps.",
        li: [],
        img: img1
    }
    return (
        <>
            <Header pagename="Interface Design" image={images} path={[{ link: "/", text: "Home" }, { link: "/interfaceDesign", text: "Interface Design" }]} />
            <div className="">
                <Services_title {...Services_title_list}></Services_title>
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_1} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_2} ></Services_content_right>
                    </div>
                </div>
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_3} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_5} ></Services_content_right>
                    </div>
                </div>
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_6} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_7} ></Services_content_right>
                    </div>
                </div>
                <div className="bg-light my-4 py-5">
                    <div className="container">
                        <Services_content {...services_content_list_8} ></Services_content>
                    </div>
                </div>
                <div className="my-4 py-2">
                    <div className="container">
                        <Services_content_right {...services_content_list_9} ></Services_content_right>
                    </div>
                </div>
            </div>
        </>
    )
}

export { InterfaceDesign }