import React from 'react'
import { Header } from "../Header/Header"
import images from "../img/Branding-IT-Service-Provider_.webp"
import { Services_title } from './Services_title'
import { Services_content } from './services_content'
import { Services_content_right } from './services_content_right'
import img from '../img/Brand/Creative-Content-Writing.webp'
import img2 from '../img/Brand/Explainer-Videos.webp'
import img3 from '../img/Brand/Graphic-Design.webp'
import img4 from '../img/Brand/Logo-&-Brand-Identity.webp'
import img5 from '../img/Brand/Print-Design.webp'
import img6 from '../img/Brand/Website-Template-Design.webp'
import { useEffect } from 'react'

function Branding() {
    useEffect(() => {
        document.title = "Branding || Coupon Locus"
     }, []);
    let Services_title_list = {
        h1:"Branding IT Service Provider",
        p1:"Allow your brand to speak for itself. We have an exceptional team of branding consultants who know how to create the spark that will keep the flame of your brand's name burning in the minds of your customers forever",
        p2:"Your brand is the message your company wishes to convey to its customers. Creating a great one would be helpful. A brand identity that fits your business message, objective, and goals so that it is imprinted in the minds of your consumers. This is precisely what Acodez does—create branding concepts that will unite individuals and businesses.",
        h2:"We offer the below brand identity solutions."
    }
    let services_content_list_0 = {
        title:"Logo & Brand Identity",
        contact:"Creating a well-designed logo is the initial step towards success. We are establishing a beautiful brand identification with your customers. Our team of brand design experts will consider your company's needs and create the ideal logo for you",
        li:[],
        img:img4
    }
    let services_content_list_1 = {
        title:"Website Template Design",
        contact:"We are one of India's best web design and development firms, delivering the newest trends and technology in web design while experimenting with innovative concepts that will draw your clients to your website's visual appeal and professional aesthetics",
        li:["Logo & Identity Design" , "Stationery design" ,"Brand Naming"],
        img:img6
    }
    let services_content_list_2 = {
        title:"Graphic Design",
        contact:"We offer excellent graphic design solutions tailored to your brand's needs. We deliver perfect graphic designs by including the perfection and aesthetics of visual communication and all areas of sensory elements such as colour, typography, space, picture, and so on.",
        li:["Brochures, Flyers and Leaflets" , "Marketing Literature & Collaterals" ,"Corporate Profile"],
        img:img3
    }

    let services_content_list_3 = {
        title:"Explainer Videos",
        contact:"The requirements of the company will determine the appropriate course of action. We can provide relevant solutions. Specialise in creating We specialise in creating a variety of videos for businesses, including animated explainer videos, corporate explainer videos, marketing videos, and demo videos.",
        li:["2D animation" , "3D animation" ,"Whiteboard animation" ,"Infographics Design" , "Custom infographics" , "Interactive infographics" , "Motion Graphics & Video Infographic"],
        img:img2
    }
    let services_content_list_4 = {
        title:"Print Design",
        contact:"You are creating print design solutions that use print language to communicate your brand to your target audience. Regardless of your industry sector, we have extensive experience in designing print designs",
        li:["Calendars & Diaries" , "Hoardings" ,"Packaging & Labels" ],
        img:img5
    }
    let services_content_list_5 = {
        title:"Creative Content Writing",
        contact:"Weaving words of magic that convey your brand's message or answer your customers seek is in our blood. We have a fantastic in-house content writing team that can create original material to lure your people and represent your brand via words",
        li:["Creative Copywriting" , "Content Copywriting"  ],
        img:img
    }
  return (
    <>
    <Header pagename="Branding" image={images} path={[{link:"/",text:"Home"},{link:"/branding",text:"Branding"}]}/>
    <div>
        <Services_title {...Services_title_list}></Services_title>
        <div className="bg-light py-5 my-4">
           <div className="container">
            <Services_content {...services_content_list_1} ></Services_content>
           </div>
        </div>
        <div className=" my-4 py-5">
        <div className="container">
            <Services_content_right {...services_content_list_2} ></Services_content_right>
           </div>
        </div>
        <div className="bg-light my-4 py-5">
           <div className="container">
            <Services_content {...services_content_list_3} ></Services_content>
           </div>
        </div>
        <div className=" my-4 py-5">
        <div className="container">
            <Services_content_right {...services_content_list_4} ></Services_content_right>
           </div>
        </div>

        <div className="bg-light my-4 py-5">
           <div className="container">
            <Services_content {...services_content_list_5} ></Services_content>
           </div>
        </div>
        <div className=" my-4 py-5">
        <div className="container">
            <Services_content_right {...services_content_list_0} ></Services_content_right>
           </div>
        </div>
    </div>
    </>
  )
}

export {Branding}